<template>
  <div class="g11-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g11Service from '@/services/g11Service'
import auth from '@/common/auth'
import utility from '@/common/utility'
import tenderService from '@/services/tenderService'
import meterageListService from '@/services/meterageListService'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G11TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程进度月计划明细表1',
        columns: [
          {
            label: '序号',
            dataKey: 'index',
            width: '50'
          },
          {
            label: '清单项目',
            dataKey: 'meterageDisplayCode',
            width: 200,
            autoMerge: true
          },
          {
            label: '工程或费用项目名称',
            dataKey: 'meterageName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      meterageList: [],
      tenderStatList: [],
      tenders: [],
      meterageMap: {},
      otherTenderStats: [],
      reportDate: ''
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('标段工程进度月计划明细表1.xlsx')
    },
    generateMapData () {
      const totalColumn = {
        label: '本月计划',
        children: [
          {
            label: '工程量',
            dataKey: 'total_currentMonthNum',
            width: 90
          },
          {
            label: '工作量（元）',
            dataKey: 'total_currentMonthAmount',
            width: 90
          },
          {
            label: '占年工程量（%）',
            dataKey: 'total_currentMonthPercent',
            width: 90
          }
        ]
      }
      this.spreadInfo.columns.push(totalColumn)

      this.tenders.forEach(tender => {
        const column = {
          label: `${tender.tenderName}`,
          children: [
            {
              label: '工程量',
              dataKey: `${tender.tenderName}_currentMonthNum`,
              width: 90
            },
            {
              label: '单价',
              dataKey: `${tender.tenderName}_price`,
              width: 90
            },
            {
              label: '工作量（元）',
              dataKey: `${tender.tenderName}_currentMonthAmount`,
              width: 90
            },
            {
              label: '占年工程量（%）',
              dataKey: `${tender.tenderName}_currentMonthPercent`,
              width: 90
            }
          ]
        }
        this.spreadInfo.columns.push(column)
      })

      this.meterageMap = {}
      this.meterageList.forEach(item => {
        item.total_currentMonthNum = 0
        item.total_currentMonthAmount = 0
        item.total_currentYearAmount = 0
        this.meterageMap[`${item.meterageDisplayCode}`] = item
      })

      this.tenderStatList.forEach(item => {
        this.meterageMap[`${item.meterageDisplayCode}`][`${item.tender.tenderName}_currentMonthNum`] = item.currentMonthNum
        this.meterageMap[`${item.meterageDisplayCode}`][`${item.tender.tenderName}_price`] = item.price
        this.meterageMap[`${item.meterageDisplayCode}`][`${item.tender.tenderName}_currentMonthAmount`] = item.currentMonthAmount
        this.meterageMap[`${item.meterageDisplayCode}`][`${item.tender.tenderName}_currentMonthPercent`] = item.currentMonthPercent
        this.meterageMap[`${item.meterageDisplayCode}`][`${item.tender.tenderName}_currentYearAmount`] = item.currentYearAmount

        this.meterageMap[`${item.meterageDisplayCode}`].total_currentMonthNum = utility.floatAdd(this.meterageMap[`${item.meterageDisplayCode}`].total_currentMonthNum, item.currentMonthNum)
        this.meterageMap[`${item.meterageDisplayCode}`].total_currentMonthAmount = utility.floatAdd(this.meterageMap[`${item.meterageDisplayCode}`].total_currentMonthAmount, item.currentMonthAmount)
        this.meterageMap[`${item.meterageDisplayCode}`].total_currentYearAmount = utility.floatAdd(this.meterageMap[`${item.meterageDisplayCode}`].total_currentYearAmount, item.currentYearAmount)
      })

      for (let key of Object.keys(this.meterageMap)) {
        const item = this.meterageMap[key]
        if (item.parentId === 0) {
          item.sortId = 0
        }
        item.total_currentMonthPercent = item.total_currentYearAmount === 0 ? 0 : Math.round(10000 * item.total_currentMonthAmount / item.total_currentYearAmount) / 100
        this.defaultDataList.push(item)
      }

      this.defaultDataList.sort((a, b) => a.sortId - b.sortId).map((item, index) => {
        item.index = index
        return item
      })

      // 合并单元格
      this.$nextTick(() => {
        const rowIndexArray = [0, 1, 2, 3, 4, 5, 6, 7]
        for (let i = 0; i < rowIndexArray.length; ++i) {
          const rowIndex = rowIndexArray[i]
          this.$refs.mySpread.worksheet.addSpan(rowIndex, 2, 1, 2)
        }
      })

      this.$loading().close()
    },
    getTenders () {
      tenderService.list({ pageIndex: 1, pageSize: 200 }, { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          const engineeringType = this.$route.query.engineeringType || '土建'
          this.tenders = res.data.data.filter(tender => tender.engineeringType === engineeringType)
          this.getData()
        })
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      this.spreadInfo.columns = [
        {
          label: '序号',
          dataKey: 'index',
          width: '50'
        },
        {
          label: '清单项目',
          dataKey: 'meterageDisplayCode',
          width: 200,
          autoMerge: true
        },
        {
          label: '工程或费用项目名称',
          dataKey: 'meterageName',
          width: 200
        },
        {
          label: '单位',
          dataKey: 'unit',
          width: 90
        }
      ]
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      const month = parseInt(this.reportDate.substr(4, 2))
      const engineeringType = this.$route.query.engineeringType || '土建'
      g11Service.getDetail1TenderStat(year, month, auth.getUserInfo().projectGuid, engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data

            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    },
    getMeterageList () {
      const engineeringType = this.$route.query.engineeringType || '土建'
      meterageListService.getAll(auth.getUserInfo().projectGuid, engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.meterageList = res.data.data
            this.getTenders()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created () {
    this.getMeterageList()
  }
}
</script>

<style scoped lang="scss">
.g11-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
